/* eslint-disable no-case-declarations */
import {
  EInputVariant,
  Input,
  Option,
  PhoneInput,
  Select,
  ESelectVariant,
  Textarea,
  ETextareaVariant,
  ETabTheme,
  SocialInput,
  ESelectBackgroundVariant,
} from '@front/shared/ds';
import { Form } from '@shared/master-types';
import React from 'react';
import { Control, Controller, UseFormRegister } from 'react-hook-form';

import {
  COUNTRY_CODE_FIELD_NAME,
  EFormInputParserVariant,
} from './FormInputParser.constants';
import { TagInputWithOptions } from './components/TagInputWithOptions';

export type TFormInputParserProps = {
  field: Required<Form>['fields'][number];
  register: UseFormRegister<Record<string, unknown>>;
  control: Control;
  variant?: EFormInputParserVariant;
  error?: string;
  customInputRenderer?: (field: any) => React.ReactNode; // eslint-disable-line @typescript-eslint/no-explicit-any
};

const FormInputParser: React.FC<TFormInputParserProps> = props => {
  const { field, register, control, error, variant } = props;

  let textareaVariant = ETextareaVariant.Primary;
  let inputVariant = EInputVariant.Primary;
  let tabTheme = ETabTheme.Outline;
  let selectBackground = ESelectBackgroundVariant.Primary;
  const defaultPhoneValidationTextError =
    'The phone number must contain 7–15 characters';

  if (variant === EFormInputParserVariant.Secondary) {
    textareaVariant = ETextareaVariant.Secondary;
    inputVariant = EInputVariant.Secondary;
    tabTheme = ETabTheme.Contained;
    selectBackground = ESelectBackgroundVariant.Secondary;
  }

  const required = field.required && {
    value: field.required,
    message: field.requiredTextError,
  };

  switch (field.preset) {
    case 'comment':
      return (
        <Textarea
          {...register(field.name, { required })}
          key={field.id}
          required={field.required}
          placeholder={field.placeholder}
          variant={textareaVariant}
        />
      );
    case 'email':
      return (
        <Input
          {...register(field.name, {
            required,
            pattern: {
              value:
                /^[a-zA-Z0-9._%+ "'/!(),:;<>@\\[\]-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
              message: field.validationTextError,
            },
          })}
          key={field.id}
          required={field.required}
          placeholder={field.placeholder}
          variant={inputVariant}
          error={error}
          type='text'
          autoComplete='email'
        />
      );
    case 'fullName':
      return (
        <Input
          {...register(field.name, {
            required,
          })}
          key={field.id}
          required={field.required}
          placeholder={field.placeholder}
          variant={inputVariant}
          error={error}
          type='text'
          autoComplete='name'
        />
      );
    case 'phone':
      // Create hidden field for country code
      const { onChange, name } = register(COUNTRY_CODE_FIELD_NAME);
      const onCountryCodeChange = (dialCode: string): void => {
        void onChange({
          type: 'change',
          target: {
            name,
            value: dialCode,
          },
        });
      };

      return (
        <Controller
          name={field.name}
          control={control}
          rules={{
            required,
            minLength: {
              value: 7,
              message:
                field.validationTextError || defaultPhoneValidationTextError,
            },
            maxLength: {
              value: 15,
              message:
                field.validationTextError || defaultPhoneValidationTextError,
            },
          }}
          render={({ field: fieldProps }) => (
            <PhoneInput
              {...fieldProps}
              error={error}
              countryLabel={field.countryLabel}
              placeholder={field.label}
              required={field.required}
              variant={inputVariant}
              onCountryCodeChange={onCountryCodeChange}
            />
          )}
        />
      );
    case 'social':
      return (
        <Controller
          name={field.name}
          control={control}
          rules={{ required }}
          render={({ field: fieldProps }) => (
            <SocialInput
              {...fieldProps}
              error={error}
              required={field.required}
              variant={inputVariant}
            />
          )}
        />
      );
    case 'tags':
      return (
        <Controller
          name={field.name}
          control={control}
          rules={{ required }}
          render={({ field: fieldProps }) => (
            <TagInputWithOptions
              {...fieldProps}
              theme={tabTheme}
              field={field}
              error={error}
            />
          )}
        />
      );
    case 'language':
      return (
        <Controller
          name={field.name}
          control={control}
          rules={{ required: field.required }}
          defaultValue={0}
          render={({ field: { ref: _ref, ...fieldProps } }) => (
            <div className='relative'>
              <Select
                variant={ESelectVariant.Input}
                key={field.id}
                backgroundVariant={selectBackground}
                options={field.options || []}
                {...field}
                {...fieldProps}
              >
                {field.options?.map(option => (
                  <Option {...option} key={option.id}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
          )}
        />
      );
    default:
      return <></>;
  }
};

export default FormInputParser;
